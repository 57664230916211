import React from "react";
import Typewriter from "typewriter-effect";

function TypeWriter() {
  return (
    <Typewriter
      options={{
        strings: [
          "Front End Development",
          "Back End Development",
          "BrainStorming",
          "Software Engineering",
          "Open Source Contribution",
          "Ideas Harvesting",
          "Data Control",
          "Social Media Marketing",
          "Project planing",
          
        ],
        autoStart: true,
        loop: true,
        deleteSpeed: 50,
      }}
    />
  );
}

export default TypeWriter;
